@import "variables";
@import "palette";

$cookie-warning__padding--desktop: 2rem;
$cookie-warning__padding--mobile: 1rem;

.cookie-warning__wrapper {
  display: block;
}

.cookie-warning {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: fade-out($color-accent, .15);
  color: $color-white;
  opacity: 0;
  z-index: -1;

  &--show {
    opacity: 1;
    z-index: 999;
  }

  &--hide {
    transition: opacity 0.3s, z-index 0s 0.4s;
  }

  &__wrapper {
    padding: $cookie-warning__padding--mobile;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  &__text-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: $cookie-warning__padding--mobile;
  }

  &__buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    button {
      margin: 0 0.5rem;
    }
  }

  &__info-icon {
    flex-basis: 30px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__text {
    line-height: $line-height--general;
    min-height: $line-height--general;
    font-size: 14px;
    padding-left: $cookie-warning__padding--mobile;
    flex-grow: 1;
    flex-shrink: 1;
  }
}

.icon {
  display: block;
  width: 30px;
  height: 30px;
  background-size: cover;

  &--info {
    background-image: url("../img/cookies.svg");
  }
}

@media screen and (min-width: $breakpoint-tablet) {

  .cookie-warning {

    &__wrapper {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: $cookie-warning__padding--desktop;
    }

    &__text-container {
      align-items: center;
      padding: 0;
    }

    &__text {
      padding-right: $cookie-warning__padding--desktop;
    }
  }

}
