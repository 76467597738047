@import "palette";

.syg-logo {
  width: 256px;
  height: 96px;
  display: flex;
  flex-direction: row;
  text-decoration: none;
}

.syg-logo__image {
  width: 96px;
  height: 96px;
  flex-grow: 0;
  flex-shrink: 0;
}

.syg-logo__text {
  margin: 0;
  padding: 1rem 0.5rem;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  color: $color-white;
}

.syg-logo__text--top {
  font-weight: 100;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.syg-logo__text--bottom {
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 2.5rem;
}
