@import "palette";
@import "variables";

.syg-footer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 1rem;
  margin: 0;
  background: fade_out($color-darker, 0.05);
  z-index: 10;

  @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
    padding: 1rem 2rem;
  }

  @media screen and (min-width: $breakpoint-desktop) {
    padding: 1.25rem 0;
    margin: -8.5rem 0 0 0;
    height: 8.5rem;
    background: transparent;
  }

  &-left, &-right {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $breakpoint-desktop) {
      flex-basis: 20%;
    }
  }

  &-left {
    flex-shrink: 1;
    flex-grow: 0;
  }

  &-right {
    flex-shrink: 0;
    flex-grow: 1;
  }

  &-link {
    padding: 0.5rem 0 0.375rem 0;

    a {
      font-size: 0.75rem;
      font-weight: 500;
    }
  }
}

.syg-credits {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $color-lighter;
  font-size: 0.5rem;

  &__link {
    margin: 0 0 0 4px;
    height: 12px;

    img {
      width: auto;
      height: 12px;
      display: block;
    }
  }
}

.syg-social-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.syg-social-button {
  display: block;
  width: 32px;
  height: 32px;
  margin-right: 16px;

  img {
    width: 32px;
    height: 32px;
  }
}

.syg-address {
  color: $color-white;
  font-size: 0.750rem;
  line-height: 0.875rem;
  padding: 0 0 0.375rem 0;

  &__title {
    font-size: 0.875rem;
    font-weight: 500;
  }
}

.syg-contacts {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0;

  a {
    font-size: 0.75rem;
    font-weight: 500;
    text-decoration: none;
  }

  img {
    width: 16px;
    height: 16px;
    padding-right: 8px;
    flex-basis: 16px;
    flex-grow: 0;
    flex-shrink: 0;
  }
}
