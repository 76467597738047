@import "palette";

[role="button"],
input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  background: none;
  border: 0;
  color: inherit;
  cursor: default;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

button:focus {
  outline: 0;
}

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

[role="button"] {
  color: inherit;
  cursor: default;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  white-space: pre;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

a[role="button"], a[role="button"]:hover, a[role="button"]:active, a[role="button"]:visited {
  color: $color-white;
}

.btn {
  position: relative;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  height: 32px;
  line-height: 32px;
  padding: 0 12px;
  border: 0 solid transparent;
  border-radius: 4px;
  box-shadow: 4px 4px 4px $color-darker;
  color: $color-white;
  background: $color-lighter;

  &:active {
    top: 2px;
    left: 2px;
    box-shadow: 2px 2px 2px $color-dark;
  }

  &-main {
    color: $color-white;
    background: $color-accent;
  }
}
