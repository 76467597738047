html, body {
  font-size: 12pt;
  line-height: 15pt;
}

.syg-text-sans {
  font-family: 'Montserrat', sans-serif;
}

.syg-text-serif {
  font-family: 'Playfair Display', serif;
}
