@import "palette";
@import "variables";

input:focus, textarea:focus, select:focus {
  outline: none;
}

.syg-form {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.syg-fields {
  margin: 0.5rem 0 1rem 0;
  padding: 0;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
}

.syg-captcha {
  height: 78px;
  display: block;
}

.syg-input {
  margin: 0 0 16px 0;
  padding: 8px;
  color: $color-darker;
  background: $color-white;
  border: 0;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1rem;
  width: 100%;
  box-sizing: border-box;

  @media screen and (min-device-width: $breakpoint-desktop) {
    width: 286px;
  }

  &.syg-textarea {
    width: 100%;
    line-height: 1.5rem;

    @media screen and (min-device-width: $breakpoint-desktop) {
      width: 464px;
    }
  }

  &::-webkit-input-placeholder {
    font-weight: 200;
    color: $color-dark;
  }

  &::-moz-placeholder {
    font-weight: 200;
    color: $color-dark;
  }

  &:-ms-input-placeholder {
    font-weight: 200;
    color: $color-dark;
  }

  &:-moz-placeholder {
    font-weight: 200;
    color: $color-dark;
  }
}
