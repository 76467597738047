@import "palette";
@import "variables";
@import "fonts";
@import "logo";
@import "button";
@import "bubbles";
@import "form";
@import "footer";
@import "cookie";

*, *:before, *:after {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
}

html {
  box-sizing: border-box;
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

a, a:hover, a:active, a:visited {
  color: $color-lighter;
}

a:hover, a:active {
  color: $color-white;
}

.syg-body {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(to bottom, $color-darker, $color-darker, $color-dark, $color-dark);
  position: relative;
}

.syg-background__image {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity ease-in-out 500ms;

  &--desktop {
    display: none;

    @media screen and (min-width: $breakpoint-desktop) {
      display: block;
    }
  }

  &--mobile {
    display: block;

    @media screen and (min-width: $breakpoint-desktop) {
      display: none;
    }
  }
}

.syg-container {
  width: 100%;
  padding: 0;
  margin: 0 auto;
  position: relative;
  z-index: 1;

  @media screen and (min-width: $breakpoint-desktop) {
    max-width: 1200px;
    min-width: 720px;
    padding: 0 40px;
  }

  &--full-height {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
}

.syg-navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 1rem 0 0 0;
  z-index: 5;
  margin: 0 1rem;

  @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
    margin: 0 2rem 0 1rem;
  }

  @media screen and (min-width: $breakpoint-desktop) {
    margin: 0;
  }
}

.syg-menu {
  display: none;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  list-style: none;
  margin: 0;
  padding: 2rem 0;

  @media screen and (min-width: $breakpoint-tablet) {
    display: flex;
  }
}

.syg-menu__item {
  padding: 0 1.5rem;

  &:last-child {
    padding-right: 0;
  }
}

.syg-menu__link {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 500;
}

.syg-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-x: hidden;
  z-index: 2;

  @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
    justify-content: flex-start;
  }

  @media screen and (min-width: $breakpoint-desktop) {
    flex-direction: row;
  }
}

.syg-main__section {
  position: relative;
  width: auto;
  padding: 1rem 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.syg-main__section-left {
  margin: 0;
  flex-shrink: 0;
  flex-grow: 0;

  @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
    flex-direction: row;
  }

  @media screen and (min-width: $breakpoint-desktop) {
    margin: 0 4rem 8.5rem 0;
    flex-shrink: 1;
    flex-grow: 1;
  }
}

.syg-mission-block {
  width: auto;
  margin: 1rem 1rem 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex-grow: 0;
  flex-shrink: 0;

  @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
    margin: 2rem;
    flex-basis: 50%;
    flex-shrink: 1;
    flex-grow: 1;
  }

  @media screen and (min-width: $breakpoint-desktop) {
    margin: 1rem 0 2rem 0;
  }

  .syg-store-link {
    width: auto;
    height: 50px;
    padding-right: 16px;
    padding-bottom: 8px;
    opacity: 0.5;

    img {
      width: auto;
      height: 42px;
    }
  }

  .syg-store-warning {
    font-size: 1.25rem;
    color: $color-lighter;
    flex-basis: 100%;
    flex-shrink: 0;
    height: 42px;
    line-height: 42px;
    @media screen and (min-width: $breakpoint-desktop) {
      flex-shrink: 1;
    }
  }

  &__label {
    position: relative;
    display: flex;
    padding-left: 16px;
    line-height: calc(2rem - 2px);
    font-size: 2rem;
    font-weight: 600;
    color: $color-white;

    span {
      padding-bottom: 2px;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 4px;
      background: $color-accent;
      transition: background-color 0.25s ease-in-out;
    }
  }

  &__text {
    margin: 0;
    padding: 1rem 0;
    color: $color-white;
    font-weight: 200;
  }

  &__buttons {
    margin: 8px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
}

.syg-main__section-middle {
  height: 5rem;
  margin: 0;
  flex-shrink: 0;
  flex-grow: 0;

  @media screen and (max-width: $breakpoint-desktop) {
    padding-top: 0;
  }

  @media screen and (min-width: $breakpoint-desktop) {
    height: auto;
    min-width: 256px;
    margin: 0 4rem 8.5rem 0;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
  }
}

.syg-app-screen-image {
  position: absolute;
  display: block;
  opacity: 0;
  width: 256px;
  height: auto;
  border-radius: 40px;
  transition: opacity 0.25s ease-in-out;

  @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
    transform: rotateY(6deg);
  }

  @media screen and (max-width: $breakpoint-tablet) {
    transform: rotateY(12deg);
  }
}

#grail-way {
  padding: 0;

  @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
    padding-left: 256px;
  }
}

#app-screen-label {
  @media screen and (min-width: $breakpoint-desktop) {
    display: none;
  }
}

.syg-mission-block__app-screen {
  position: relative;
  width: 256px;
  height: 100%;
  margin: 0 auto 0 0;
  padding: 4rem 0 0 0;
  perspective: 1200px;
  transform-style: flat;
  perspective-origin: center 320px;

  &:before {
    position: absolute;
    content: "";
    display: block;
    background-color: $color-darker;
    top: $image-phone-offset;
    left: $image-phone-offset;
    width: 256px - 2 * $image-phone-offset;
    height: 512px - 2 * $image-phone-offset;
    border-radius: 40px;
    box-shadow: 0 0 8px $color-darker;

    @media screen and (max-width: $breakpoint-desktop) {
      top: 64px + $image-phone-offset;
    }

    @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
      transform: rotateY(6deg);
    }

    @media screen and (max-width: $breakpoint-tablet) {
      transform: rotateY(12deg);
    }
  }

  @media screen and (min-width: $breakpoint-desktop) {
    padding: 0;
    margin: 0.5rem auto;
    perspective: none;
    transform-style: unset;
    perspective-origin: unset;
  }
}

#grail-way-label {
  display: none;
}

.syg-main__section-right {
  width: auto;
  padding: 0;
  flex-shrink: 0;
  flex-grow: 0;
  border-top: 4px solid $color-darker;
  background: linear-gradient(to right, transparent, fade_out($color-darker, 0.625), fade_out($color-darker, 0.25), fade_out($color-darker, 0.15), fade_out($color-darker, 0.05));

  @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
    flex-grow: 1;
  }

  @media screen and (min-width: $breakpoint-desktop) {
    width: 256px;
    margin: 0 0 8.5rem 0;
    padding: 1rem 0 0 0;
    border: none;
    background: transparent;
  }
}

.syg-mission-block__grail-way {
  margin: 1rem 0 0 auto;
  position: relative;

  @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
    margin-right: auto;
  }

  @media screen and (min-width: $breakpoint-desktop) {
    margin: 0;
  }
}
