@import "palette";
@import "variables";

$handle-thickness: 4px;
$bubble-padding: 8px;

.sig-bubble {
  visibility: hidden;
  cursor: pointer;
  margin: 0;
  padding: 8px $bubble-padding;
  background: $color-white;
  border: 1px solid $color-lighter;
  border-radius: 8px;
  position: absolute;
  overflow: hidden;
  line-height: 1.0rem;
  font-size: 0.75rem;
  font-weight: 700;
  color: $color-light;
  box-shadow: 0 0 8px $color-darker;
  transition: box-shadow 0.25s ease-in-out, background-color 0.25s ease-in-out;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: $handle-thickness;
    background: $color-lighter;
    transition: background-color 0.25s ease-in-out;
  }

  &--left {
    padding-left: $bubble-padding + $handle-thickness;

    &:after {
      left: 0;
    }
  }

  &--right {
    padding-right: $bubble-padding + $handle-thickness;

    &:after {
      right: 0;
    }
  }

  &--selected {
    color: $color-darker;
    box-shadow: 0 0 4px $color-accent;
    border-color: $color-light;
    background-color: lighten($color-accent, 33%);

    &:after {
      background: $color-accent;
    }
  }

  &--first {
    width: 176px;
    height: 50px;
    top: 8px;
    left: 0;
  }

  &--second {
    width: 184px;
    height: 66px;
    top: 80px;
    left: 64px;
  }

  &--third {
    width: 208px;
    height: 66px;
    top: 168px;
    left: 0;
  }

  &--fourth {
    width: 200px;
    height: 50px;
    top: 264px;
    left: 48px;
  }

  &--fifth {
    width: 208px;
    height: 50px;
    top: 368px;
    left: 0;
  }

  &__animated {
    visibility: visible;
    animation-duration: 500ms;
    animation-timing-function: ease;
    animation-fill-mode: both;

    &.sig-bubble--first {
      animation-name: bubble-odd;
      animation-delay: 0.5s;

      @media screen and (min-width: $breakpoint-desktop) {
        animation-delay: 1s;
      }
    }

    &.sig-bubble--second {
      animation-name: bubble-second;
      animation-delay: 0.75s;

      @media screen and (min-width: $breakpoint-desktop) {
        animation-delay: 1.5s;
      }
    }

    &.sig-bubble--third {
      animation-name: bubble-odd;
      animation-delay: 1.0s;

      @media screen and (min-width: $breakpoint-desktop) {
        animation-delay: 2s;
      }
    }

    &.sig-bubble--fourth {
      animation-name: bubble-fourth;
      animation-delay: 1.25s;

      @media screen and (min-width: $breakpoint-desktop) {
        animation-delay: 2.5s;
      }
    }

    &.sig-bubble--fifth {
      animation-name: bubble-odd;
      animation-delay: 1.5s;

      @media screen and (min-width: $breakpoint-desktop) {
        animation-delay: 3s;
      }
    }
  }
}

@keyframes bubble-odd {
  from {
    opacity: 0;
    left: -8px;
  }
  to {
    opacity: 1;
    left: 0;
  }
}

@keyframes bubble-second {
  from {
    opacity: 0;
    left: 72px;
  }
  to {
    opacity: 1;
    left: 64px;
  }
}

@keyframes bubble-fourth {
  from {
    opacity: 0;
    left: 56px;
  }
  to {
    opacity: 1;
    left: 48px;
  }
}
